import React, {useEffect, useState, Fragment} from "react";
import {GoogleMap, InfoWindow, LoadScript, Marker, OverlayView, useLoadScript} from "@react-google-maps/api";

const wjruClubs  = [
  {
    name: 'Collaroy Rugby Club',
    url: '/warringah-rats-junior-rugby/collaroy-cougars-rugby-club/',
    icon: require('./image/collaroy.png'),
    position: {
      lat: -33.739107,
      lng: 151.304746
    },
  }, {
    name: 'Newport Rugby Club',
    url: '/warringah-rats-junior-rugby/newport-rugby-club/',
    icon: require('./image/newport.png'),
    position: {
      lat: -33.6490031,
      lng: 151.32272569999998
    }
  }, {
    name: 'Terrey Hills Rugby Club',
    url: '/warringah-rats-junior-rugby/terrey-hills-rugby-club/',
    icon: require('./image/terreyhills.png'),
    position: {
      lat: -33.68175840256318,
      lng: 151.2298877663269
    }
  }, {
    name: 'Dee Why Rugby Club',
    url: '/warringah-rats-junior-rugby/dee-why-lions-rugby-club/',
    icon: require('./image/deewhy.png'),
    position: {
      lat: -33.74152699969818,
      lng: 151.28171071960446
    }
  }, {
    name: 'Pittwater Rugby Club',
    url: '/warringah-rats-junior-rugby/pittwater-dolphins-rufc/',
    icon: require('./image/pittwater.png'),
    position: {
      lat: -33.668235797633656,
      lng: 151.30254965501706
    }
  }, {
    name: 'Narrabeen Rugby Club',
    url: '/warringah-rats-junior-rugby/narrabeen-tigers-rugby-club/',
    icon: require('./image/narrabeen.png'),
    position: {
      lat: -33.696912,
      lng: 151.303794
    }
  }, {
    name: 'Wakehurst Rugby Club',
    url: '/warringah-rats-junior-rugby/wakehurst-rugby-club/',
    icon: require('./image/wakehurst.png'),
    position: {
      lat: -33.714749,
      lng: 151.215902
    }
  }
]

export const Map = () => {

  // useLoadScript({
  //   googleMapsApiKey: 'AIzaSyBl9NJwTdvxVwhoKpridvdnY_jppvxd0fQ'
  // });

  const [ clubs, setClubs] = useState<any>([]);
  const [ selected, setSelected] = useState<any>(null);

  useEffect(() => {
    console.log('Loading....');

    // loadClubs();
  }, [])

  const loadClubs = async () => {
    const response = await fetch('http://localhost:7070/wjru/api/v1/clubs');//.then(res => res.json);
    const embedded = await response.json();
    console.log(embedded._embedded);
    setClubs(embedded._embedded.clubs.map(convertClub));
  }

  const convertClub = (club: any) => {
    return {
      id: club.id,
      name: club.name,
      position: { lat: club.lat, lng: club.lng}
    }
  }
  // const logo = require('./image/collaroy.png')

  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const center = {
    lat: -33.696912,
    lng: 151.303794
  };

  // const icon = {
  //   url: 'http://wjru2.obicloud.com.au/wp-content/uploads/2020/05/collaroy.png',
  //   scaledSize: { width: 30, height: 30 }
  // }

  const showClub = (club: any) => {
    // console.log('showCLub');
    // console.log(args);
    // club.popup = true;
    setSelected(club);
  }

  const ClubMarker = (club: any) => {
    // console.log(club);
    return club.icon ?
      <div className='clubMarker' onClick={() => showClub(club)}>
        <img src={club.icon}/>
      </div>
     :
      <Marker
        position={club.position}
        onClick={() => showClub(club)}></Marker>

  }

  const isSelected = (club: any) => selected !== null && selected.name === club.name;

  const toOverlay = (club: any) =>
    <Fragment key={club.name}> -s *
      <OverlayView
        position={club.position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        { club.icon ?
          <div className='clubMarker' onClick={() => showClub(club)}>
            <img src={club.icon}/>
          </div>
          :
          <Marker position={club.position} onClick={() => showClub(club)}/>
        }
        {/*<ClubMarker club={club}/>*/}
      </OverlayView>
      {isSelected(club) ?
        <InfoWindow position={club.position}>
          <div><a href={club.url}>{club.name}</a></div>
        </InfoWindow> : null
      }
    </Fragment>
  // const offset = (lat, lng)

  // const toMarker = (club: any) => <OverlayView mapPaneName='OverlayView.OVERLAY_MOUSE_TARGET' key={club.name} position={club.position}><div style={containerStyle}><p>aaa {club.name}</p></div></OverlayView>
  // const toMarker = (club: any) => <Marker key={club.name} position={club.position} icon={icon} onClick={showClub}/>
  // const toMarker = (club: any) => <ClubMarker key={club.name} club={club}/>
  // const toInfoWindow = (club: any) => <InfoWindow position={club.position}><h1>{club.name}</h1></InfoWindow>

  return (
    <LoadScript googleMapsApiKey='AIzaSyBl9NJwTdvxVwhoKpridvdnY_jppvxd0fQ'>
    <GoogleMap
      ref={map => {

      }}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={11}
    >

      {/*{clubs.map(toOverlay)}*/}
      {wjruClubs.map(toOverlay)}
    </GoogleMap>
    </LoadScript>
  )
}

export default Map;
