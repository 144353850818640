import React from 'react';
import Map from './Map';
import Map2 from './Map2';
import Main from './Main';
import './App.css';

function App() {
  return (
    <div className="App">
      {/*<Main/>*/}
      <Map/>
    </div>
  );
}

export default App;
